exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-covers-20-good-reasons-js": () => import("./../../../src/pages/covers/20-good-reasons.js" /* webpackChunkName: "component---src-pages-covers-20-good-reasons-js" */),
  "component---src-pages-covers-3-am-js": () => import("./../../../src/pages/covers/3-am.js" /* webpackChunkName: "component---src-pages-covers-3-am-js" */),
  "component---src-pages-covers-6th-avenue-heartache-js": () => import("./../../../src/pages/covers/6th-avenue-heartache.js" /* webpackChunkName: "component---src-pages-covers-6th-avenue-heartache-js" */),
  "component---src-pages-covers-astrovan-js": () => import("./../../../src/pages/covers/astrovan.js" /* webpackChunkName: "component---src-pages-covers-astrovan-js" */),
  "component---src-pages-covers-babylon-js": () => import("./../../../src/pages/covers/babylon.js" /* webpackChunkName: "component---src-pages-covers-babylon-js" */),
  "component---src-pages-covers-bird-stealing-bread-js": () => import("./../../../src/pages/covers/bird-stealing-bread.js" /* webpackChunkName: "component---src-pages-covers-bird-stealing-bread-js" */),
  "component---src-pages-covers-californication-js": () => import("./../../../src/pages/covers/californication.js" /* webpackChunkName: "component---src-pages-covers-californication-js" */),
  "component---src-pages-covers-clarity-js": () => import("./../../../src/pages/covers/clarity.js" /* webpackChunkName: "component---src-pages-covers-clarity-js" */),
  "component---src-pages-covers-country-road-js": () => import("./../../../src/pages/covers/country-road.js" /* webpackChunkName: "component---src-pages-covers-country-road-js" */),
  "component---src-pages-covers-cover-me-up-js": () => import("./../../../src/pages/covers/cover-me-up.js" /* webpackChunkName: "component---src-pages-covers-cover-me-up-js" */),
  "component---src-pages-covers-crescent-moon-js": () => import("./../../../src/pages/covers/crescent-moon.js" /* webpackChunkName: "component---src-pages-covers-crescent-moon-js" */),
  "component---src-pages-covers-dead-sea-js": () => import("./../../../src/pages/covers/dead-sea.js" /* webpackChunkName: "component---src-pages-covers-dead-sea-js" */),
  "component---src-pages-covers-dont-panic-js": () => import("./../../../src/pages/covers/dont-panic.js" /* webpackChunkName: "component---src-pages-covers-dont-panic-js" */),
  "component---src-pages-covers-eliza-jane-js": () => import("./../../../src/pages/covers/eliza-jane.js" /* webpackChunkName: "component---src-pages-covers-eliza-jane-js" */),
  "component---src-pages-covers-everlong-js": () => import("./../../../src/pages/covers/everlong.js" /* webpackChunkName: "component---src-pages-covers-everlong-js" */),
  "component---src-pages-covers-every-morning-js": () => import("./../../../src/pages/covers/every-morning.js" /* webpackChunkName: "component---src-pages-covers-every-morning-js" */),
  "component---src-pages-covers-fire-and-rain-js": () => import("./../../../src/pages/covers/fire-and-rain.js" /* webpackChunkName: "component---src-pages-covers-fire-and-rain-js" */),
  "component---src-pages-covers-fruits-of-my-labor-js": () => import("./../../../src/pages/covers/fruits-of-my-labor.js" /* webpackChunkName: "component---src-pages-covers-fruits-of-my-labor-js" */),
  "component---src-pages-covers-georgia-js": () => import("./../../../src/pages/covers/georgia.js" /* webpackChunkName: "component---src-pages-covers-georgia-js" */),
  "component---src-pages-covers-give-me-one-reason-js": () => import("./../../../src/pages/covers/give-me-one-reason.js" /* webpackChunkName: "component---src-pages-covers-give-me-one-reason-js" */),
  "component---src-pages-covers-halfway-up-js": () => import("./../../../src/pages/covers/halfway-up.js" /* webpackChunkName: "component---src-pages-covers-halfway-up-js" */),
  "component---src-pages-covers-hard-place-js": () => import("./../../../src/pages/covers/hard-place.js" /* webpackChunkName: "component---src-pages-covers-hard-place-js" */),
  "component---src-pages-covers-high-and-dry-js": () => import("./../../../src/pages/covers/high-and-dry.js" /* webpackChunkName: "component---src-pages-covers-high-and-dry-js" */),
  "component---src-pages-covers-how-do-you-get-em-back-js": () => import("./../../../src/pages/covers/how-do-you-get-em-back.js" /* webpackChunkName: "component---src-pages-covers-how-do-you-get-em-back-js" */),
  "component---src-pages-covers-i-can-change-js": () => import("./../../../src/pages/covers/i-can-change.js" /* webpackChunkName: "component---src-pages-covers-i-can-change-js" */),
  "component---src-pages-covers-i-will-remain-js": () => import("./../../../src/pages/covers/i-will-remain.js" /* webpackChunkName: "component---src-pages-covers-i-will-remain-js" */),
  "component---src-pages-covers-if-i-ever-lose-my-faith-in-you-js": () => import("./../../../src/pages/covers/if-i-ever-lose-my-faith-in-you.js" /* webpackChunkName: "component---src-pages-covers-if-i-ever-lose-my-faith-in-you-js" */),
  "component---src-pages-covers-if-you-could-only-see-js": () => import("./../../../src/pages/covers/if-you-could-only-see.js" /* webpackChunkName: "component---src-pages-covers-if-you-could-only-see-js" */),
  "component---src-pages-covers-imitosis-js": () => import("./../../../src/pages/covers/imitosis.js" /* webpackChunkName: "component---src-pages-covers-imitosis-js" */),
  "component---src-pages-covers-just-the-two-of-us-js": () => import("./../../../src/pages/covers/just-the-two-of-us.js" /* webpackChunkName: "component---src-pages-covers-just-the-two-of-us-js" */),
  "component---src-pages-covers-keep-it-loose-keep-it-tight-js": () => import("./../../../src/pages/covers/keep-it-loose-keep-it-tight.js" /* webpackChunkName: "component---src-pages-covers-keep-it-loose-keep-it-tight-js" */),
  "component---src-pages-covers-late-night-talking-js": () => import("./../../../src/pages/covers/late-night-talking.js" /* webpackChunkName: "component---src-pages-covers-late-night-talking-js" */),
  "component---src-pages-covers-laundry-room-js": () => import("./../../../src/pages/covers/laundry-room.js" /* webpackChunkName: "component---src-pages-covers-laundry-room-js" */),
  "component---src-pages-covers-levitating-js": () => import("./../../../src/pages/covers/levitating.js" /* webpackChunkName: "component---src-pages-covers-levitating-js" */),
  "component---src-pages-covers-little-lion-man-js": () => import("./../../../src/pages/covers/little-lion-man.js" /* webpackChunkName: "component---src-pages-covers-little-lion-man-js" */),
  "component---src-pages-covers-lost-in-japan-js": () => import("./../../../src/pages/covers/lost-in-japan.js" /* webpackChunkName: "component---src-pages-covers-lost-in-japan-js" */),
  "component---src-pages-covers-maneater-js": () => import("./../../../src/pages/covers/maneater.js" /* webpackChunkName: "component---src-pages-covers-maneater-js" */),
  "component---src-pages-covers-mariners-revenge-song-js": () => import("./../../../src/pages/covers/mariners-revenge-song.js" /* webpackChunkName: "component---src-pages-covers-mariners-revenge-song-js" */),
  "component---src-pages-covers-meet-virginia-js": () => import("./../../../src/pages/covers/meet-virginia.js" /* webpackChunkName: "component---src-pages-covers-meet-virginia-js" */),
  "component---src-pages-covers-my-favorite-mistake-js": () => import("./../../../src/pages/covers/my-favorite-mistake.js" /* webpackChunkName: "component---src-pages-covers-my-favorite-mistake-js" */),
  "component---src-pages-covers-neon-js": () => import("./../../../src/pages/covers/neon.js" /* webpackChunkName: "component---src-pages-covers-neon-js" */),
  "component---src-pages-covers-older-chests-js": () => import("./../../../src/pages/covers/older-chests.js" /* webpackChunkName: "component---src-pages-covers-older-chests-js" */),
  "component---src-pages-covers-rich-girl-js": () => import("./../../../src/pages/covers/rich-girl.js" /* webpackChunkName: "component---src-pages-covers-rich-girl-js" */),
  "component---src-pages-covers-rivers-and-roads-js": () => import("./../../../src/pages/covers/rivers-and-roads.js" /* webpackChunkName: "component---src-pages-covers-rivers-and-roads-js" */),
  "component---src-pages-covers-royals-js": () => import("./../../../src/pages/covers/royals.js" /* webpackChunkName: "component---src-pages-covers-royals-js" */),
  "component---src-pages-covers-say-you-wont-let-go-js": () => import("./../../../src/pages/covers/say-you-wont-let-go.js" /* webpackChunkName: "component---src-pages-covers-say-you-wont-let-go-js" */),
  "component---src-pages-covers-semi-charmed-life-js": () => import("./../../../src/pages/covers/semi-charmed-life.js" /* webpackChunkName: "component---src-pages-covers-semi-charmed-life-js" */),
  "component---src-pages-covers-slide-js": () => import("./../../../src/pages/covers/slide.js" /* webpackChunkName: "component---src-pages-covers-slide-js" */),
  "component---src-pages-covers-stay-with-you-js": () => import("./../../../src/pages/covers/stay-with-you.js" /* webpackChunkName: "component---src-pages-covers-stay-with-you-js" */),
  "component---src-pages-covers-stop-this-train-js": () => import("./../../../src/pages/covers/stop-this-train.js" /* webpackChunkName: "component---src-pages-covers-stop-this-train-js" */),
  "component---src-pages-covers-summertime-magic-js": () => import("./../../../src/pages/covers/summertime-magic.js" /* webpackChunkName: "component---src-pages-covers-summertime-magic-js" */),
  "component---src-pages-covers-sunday-morning-js": () => import("./../../../src/pages/covers/sunday-morning.js" /* webpackChunkName: "component---src-pages-covers-sunday-morning-js" */),
  "component---src-pages-covers-sweet-pea-js": () => import("./../../../src/pages/covers/sweet-pea.js" /* webpackChunkName: "component---src-pages-covers-sweet-pea-js" */),
  "component---src-pages-covers-tangled-js": () => import("./../../../src/pages/covers/tangled.js" /* webpackChunkName: "component---src-pages-covers-tangled-js" */),
  "component---src-pages-covers-the-a-team-js": () => import("./../../../src/pages/covers/the-a-team.js" /* webpackChunkName: "component---src-pages-covers-the-a-team-js" */),
  "component---src-pages-covers-the-bad-days-js": () => import("./../../../src/pages/covers/the-bad-days.js" /* webpackChunkName: "component---src-pages-covers-the-bad-days-js" */),
  "component---src-pages-covers-the-crane-wife-3-js": () => import("./../../../src/pages/covers/the-crane-wife-3.js" /* webpackChunkName: "component---src-pages-covers-the-crane-wife-3-js" */),
  "component---src-pages-covers-the-stable-song-js": () => import("./../../../src/pages/covers/the-stable-song.js" /* webpackChunkName: "component---src-pages-covers-the-stable-song-js" */),
  "component---src-pages-covers-trouble-js": () => import("./../../../src/pages/covers/trouble.js" /* webpackChunkName: "component---src-pages-covers-trouble-js" */),
  "component---src-pages-covers-vienna-js": () => import("./../../../src/pages/covers/vienna.js" /* webpackChunkName: "component---src-pages-covers-vienna-js" */),
  "component---src-pages-covers-wake-me-up-js": () => import("./../../../src/pages/covers/wake-me-up.js" /* webpackChunkName: "component---src-pages-covers-wake-me-up-js" */),
  "component---src-pages-covers-whats-been-going-on-mdx": () => import("./../../../src/pages/covers/whats-been-going-on.mdx" /* webpackChunkName: "component---src-pages-covers-whats-been-going-on-mdx" */),
  "component---src-pages-covers-why-georgia-js": () => import("./../../../src/pages/covers/why-georgia.js" /* webpackChunkName: "component---src-pages-covers-why-georgia-js" */),
  "component---src-pages-covers-wind-cries-mary-js": () => import("./../../../src/pages/covers/wind-cries-mary.js" /* webpackChunkName: "component---src-pages-covers-wind-cries-mary-js" */),
  "component---src-pages-covers-yellow-eyes-js": () => import("./../../../src/pages/covers/yellow-eyes.js" /* webpackChunkName: "component---src-pages-covers-yellow-eyes-js" */),
  "component---src-pages-covers-you-are-the-best-thing-js": () => import("./../../../src/pages/covers/you-are-the-best-thing.js" /* webpackChunkName: "component---src-pages-covers-you-are-the-best-thing-js" */),
  "component---src-pages-covers-younger-js": () => import("./../../../src/pages/covers/younger.js" /* webpackChunkName: "component---src-pages-covers-younger-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-originals-baby-i-dont-know-js": () => import("./../../../src/pages/originals/baby-i-dont-know.js" /* webpackChunkName: "component---src-pages-originals-baby-i-dont-know-js" */),
  "component---src-pages-originals-back-to-life-mdx": () => import("./../../../src/pages/originals/back-to-life.mdx" /* webpackChunkName: "component---src-pages-originals-back-to-life-mdx" */),
  "component---src-pages-originals-black-and-white-js": () => import("./../../../src/pages/originals/black-and-white.js" /* webpackChunkName: "component---src-pages-originals-black-and-white-js" */),
  "component---src-pages-originals-burn-out-js": () => import("./../../../src/pages/originals/burn-out.js" /* webpackChunkName: "component---src-pages-originals-burn-out-js" */),
  "component---src-pages-originals-can-i-be-honest-js": () => import("./../../../src/pages/originals/can-i-be-honest.js" /* webpackChunkName: "component---src-pages-originals-can-i-be-honest-js" */),
  "component---src-pages-originals-days-are-going-fast-js": () => import("./../../../src/pages/originals/days-are-going-fast.js" /* webpackChunkName: "component---src-pages-originals-days-are-going-fast-js" */),
  "component---src-pages-originals-deception-js": () => import("./../../../src/pages/originals/deception.js" /* webpackChunkName: "component---src-pages-originals-deception-js" */),
  "component---src-pages-originals-devious-js": () => import("./../../../src/pages/originals/devious.js" /* webpackChunkName: "component---src-pages-originals-devious-js" */),
  "component---src-pages-originals-germain-towers-js": () => import("./../../../src/pages/originals/germain-towers.js" /* webpackChunkName: "component---src-pages-originals-germain-towers-js" */),
  "component---src-pages-originals-hold-on-to-tomorrow-js": () => import("./../../../src/pages/originals/hold-on-to-tomorrow.js" /* webpackChunkName: "component---src-pages-originals-hold-on-to-tomorrow-js" */),
  "component---src-pages-originals-hurricane-brown-eyes-mdx": () => import("./../../../src/pages/originals/hurricane-brown-eyes.mdx" /* webpackChunkName: "component---src-pages-originals-hurricane-brown-eyes-mdx" */),
  "component---src-pages-originals-i-would-have-left-js": () => import("./../../../src/pages/originals/i-would-have-left.js" /* webpackChunkName: "component---src-pages-originals-i-would-have-left-js" */),
  "component---src-pages-originals-lockdown-love-js": () => import("./../../../src/pages/originals/lockdown-love.js" /* webpackChunkName: "component---src-pages-originals-lockdown-love-js" */),
  "component---src-pages-originals-march-js": () => import("./../../../src/pages/originals/march.js" /* webpackChunkName: "component---src-pages-originals-march-js" */),
  "component---src-pages-originals-of-consequence-js": () => import("./../../../src/pages/originals/of-consequence.js" /* webpackChunkName: "component---src-pages-originals-of-consequence-js" */),
  "component---src-pages-originals-rather-be-lonely-js": () => import("./../../../src/pages/originals/rather-be-lonely.js" /* webpackChunkName: "component---src-pages-originals-rather-be-lonely-js" */),
  "component---src-pages-originals-rescue-me-js": () => import("./../../../src/pages/originals/rescue-me.js" /* webpackChunkName: "component---src-pages-originals-rescue-me-js" */),
  "component---src-pages-originals-rushing-back-js": () => import("./../../../src/pages/originals/rushing-back.js" /* webpackChunkName: "component---src-pages-originals-rushing-back-js" */),
  "component---src-pages-originals-silver-screen-js": () => import("./../../../src/pages/originals/silver-screen.js" /* webpackChunkName: "component---src-pages-originals-silver-screen-js" */),
  "component---src-pages-originals-where-does-that-leave-us-js": () => import("./../../../src/pages/originals/where-does-that-leave-us.js" /* webpackChunkName: "component---src-pages-originals-where-does-that-leave-us-js" */),
  "component---src-pages-originals-wrong-side-of-town-js": () => import("./../../../src/pages/originals/wrong-side-of-town.js" /* webpackChunkName: "component---src-pages-originals-wrong-side-of-town-js" */),
  "component---src-pages-originals-yesterday-mdx": () => import("./../../../src/pages/originals/yesterday.mdx" /* webpackChunkName: "component---src-pages-originals-yesterday-mdx" */)
}

